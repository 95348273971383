<template>
  <div>
    <div class="row mt-3">
      <!-- 平台用户 -->
      <div v-if="currentUser.userType == 1" class="col-lg-7">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-60 symbol-lg-120">
                  <img src="media/users/default.jpg" alt="image" />
                </div>
              </div>

              <div class="flex-grow-1">
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a class="
                        text-dark-75 text-hover-primary
                        font-size-h5 font-weight-bold
                        mr-3
                      ">
                      真实名称:{{ currentUser.realName }}
                    </a>
                  </div>
                  <div class="my-lg-0 my-3">
                    <a href="#" class="
                        btn btn-sm btn-light-success
                        font-weight-bolder
                        text-uppercase
                        mr-3
                      ">
                      <router-link to="/profile">个人中心</router-link>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a href="#" class="
                          text-dark-50 text-hover-primary
                          font-weight-bold
                          mr-lg-8 mr-5
                          mb-lg-0 mb-2
                        "><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>登录账号: {{ currentUser.userName }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="separator separator-solid"></div>
            <div class="d-flex align-items-center flex-wrap mt-8">
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon2-chart2
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">订单总量</span>
                  <span class="font-weight-bolder font-size-h5">{{ accountInfo.totalCount }} </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-coins display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">订单总金额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.totalAmount }}
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon2-cup
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">交易成功金额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.successAmount }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon2-correct
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">交易成功数量</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.successCount }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 商户用户 -->
      <div v-if="currentUser.userType == 2" class="col-lg-7">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-60 symbol-lg-120">
                  <img src="media/users/default.jpg" alt="image" />
                </div>
              </div>

              <div class="flex-grow-1">
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a class="
                        text-dark-75 text-hover-primary
                        font-size-h5 font-weight-bold
                        mr-3
                      ">
                      商户名称:{{ accountInfo.merchantName }}
                    </a>
                  </div>
                  <div class="my-lg-0 my-3">
                    <a href="#" class="
                        btn btn-sm btn-light-success
                        font-weight-bolder
                        text-uppercase
                        mr-3
                      ">
                      <router-link to="/profile">个人中心</router-link>
                    </a>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a href="#" class="
                          text-dark-50 text-hover-primary
                          font-weight-bold
                          mr-lg-8 mr-5
                          mb-lg-0 mb-2
                        "><i class="fas fa-key mr-2 font-size-lg"></i>商户ID:
                        {{ accountInfo.merchantId }}
                      </a>
                      <a href="#" class="
                          text-dark-50 text-hover-primary
                          font-weight-bold
                          mr-lg-8 mr-5
                          mb-lg-0 mb-2
                        "><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>登录账号: {{ currentUser.userName }}
                      </a>
                    </div>
                    <div class="d-flex align-items-center flex-fill float-right">
                      <span class="font-weight-bold text-dark-75">通道&费率：</span>
                      <div class="mx-3">
                        <b-badge class="mr-3" variant="success" v-if="item.status == 1" :key="index"
                          v-for="(item, index) in accountInfo.payRules">{{ item.payTypeName }} / {{ item.feeRate }}
                        </b-badge>
                      </div>
                      <span class="mx-3 font-weight-bold text-dark-75">结算周期：</span>
                      <div>
                        <b-badge class="mr-3" variant="success" v-if="accountInfo.settleType == -2">D0</b-badge>
                        <b-badge class="mr-3" variant="success" v-if="accountInfo.settleType == -1">D0</b-badge>
                        <b-badge class="mr-3" variant="success" v-else-if="accountInfo.settleType == 0">T+0</b-badge>
                        <b-badge class="mr-3" variant="success" v-else-if="accountInfo.settleType == 1">T+1</b-badge>
                        <b-badge class="mr-3" variant="success" v-else-if="accountInfo.settleType == 7">T+</b-badge>
                      </div>
                    </div>

                    <div class="d-flex align-items-center flex-fill float-right mt-2">
                      <span class="font-weight-bold text-dark-75">提现费率：</span>
                      <div class="mx-3">
                        <b>{{ accountInfo.withdrawRate }} </b>
                      </div>
                      <span class="font-weight-bold text-dark-75">提现单笔手续费：</span>
                      <div class="mx-3">
                        <b>$ {{ accountInfo.withdrawFee }} </b>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="separator separator-solid"></div>
            <div class="d-flex align-items-center flex-wrap mt-8">
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon-piggy-bank
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">账户余额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.balance }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon2-protected
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">冻结余额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.freezeBalance }}
                  </span>
                </div>
              </div>



              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-coins display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">待结算金额</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.unSettleAmount }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="
                      flaticon2-chart2
                      display-3
                      text-muted
                      font-weight-bold
                    "></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">订单总量</span>
                  <span class="font-weight-bolder font-size-h5">{{ accountInfo.totalCount }} </span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="far fa-credit-card display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">提现金额（今日）</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>{{ accountInfo.withdrawAmount }}
                  </span>
                </div>
              </div>


              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon2-line-chart display-3 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">提现成功率（今日）</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold"></span>{{ accountInfo.withdrawSuccessRate }}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0" style="max-height: 50px !important">
            <h6 class="card-title font-weight-bolder text-dark-75">
              近三次登录记录
            </h6>
            <div class="card-toolbar">
              <button title="更多" aria-haspopup="true" aria-expanded="false" type="button" class="
                  btn
                  dropdown-toggle
                  btn-link btn-sm
                  custom-v-dropdown
                  btn btn-clean btn-hover-light-primary btn-sm btn-icon
                  dropdown-toggle-no-caret
                ">
                <router-link v-if="currentUser.userType == 1" to="/log/list">
                  <i class="ki ki-bold-more-hor"> </i>
                </router-link>
                <router-link v-if="currentUser.userType == 2" to="/merch/logs/list">
                  <i class="ki ki-bold-more-hor"> </i>
                </router-link>
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-left">
                    <th style="min-width: 120px">登录时间</th>
                    <th style="min-width: 110px">地点</th>
                    <th style="min-width: 110px">ip地址</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(item, index) in last3Logs">
                    <td>{{ item.optTime | formatDate }}</td>
                    <td>{{ item.location ? item.location : " - " }}</td>
                    <td>{{ item.ipAddress }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">交易统计 </span><span
            class="text-muted mt-3 font-weight-bold font-size-sm">
            近期订单量与交易额统计
          </span>
        </h3>
        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a data-toggle="tab" @click="changeRecent(7)"
                :class="[rencent == 7 ? 'nav-link py-2 px-4 active' : 'nav-link py-2 px-4 ']">
                近一周
              </a>
            </li>
            <li class="nav-item">
              <a data-toggle="tab" @click="changeRecent(30)"
                :class="[rencent == 30 ? 'nav-link py-2 px-4 active' : 'nav-link py-2 px-4 ']"> 近一月 </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pt-3 pb-0">
        <div class="table-responsive">
          <table class="table table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th class="p-0">日期</th>
                <th class="p-0">订单总金额</th>
                <th class="p-0">订单总量</th>
                <th class="p-0">成功金额</th>
                <th class="p-0">成功数量</th>
                <th class="p-0">失败金额 </th>
                <th class="p-0">失败数量</th>
                <th class="p-0">成功率</th>

              </tr>
            </thead>
            <tbody>
              <tr v-if="reports.length < 1">
                <td align="center" colspan="8">暂无数据...</td>
              </tr>

              <tr :key="index" v-for="(item, index) in reports">
                <td class="text-left">{{ item.date }}</td>
                <td class="text-left">{{ item.totalAmount }}</td>
                <td class="text-left">{{ item.totalCount }}</td>
                <td class="text-left">{{ item.successAmount }}</td>
                <td class="text-left">{{ item.successCount }}</td>
                <td class="text-left">{{ item.failAmount }}</td>
                <td class="text-left">{{ item.failCount }}</td>
                <td class="text-left">{{ item.successRate }} %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import {
  mapGetters
} from "vuex";
import ApiService from "@/core/services/api.service";
import Moment from "moment";

export default {
  name: "widget-2",
  data() {
    return {
      last3Logs: [],
      rencent: 7,
      accountInfo: {},
      reports: [],
      startTime: Moment(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD"),
      endTime: Moment(new Date()).format("YYYY-MM-DD"),

    };
  },
  components: {
    Dropdown2,
  },
  created() {
    this.getLast3Logs();
    this.getAccountInfo();
    this.getReport();
  },
  methods: {
    getLast3Logs() {
      let that = this;
      ApiService.query("/api/logs/last3", {
        params: {},
      }).then(({
        data
      }) => {
        that.last3Logs = data;
      });
    },
    getAccountInfo() {
      ApiService.get("/api/dashboard").then(({
        data
      }) => {
        if (data.success) {
          this.accountInfo = data.result;
        }
      });
    },
    getReport() {
      let params = {
        "startTime": this.startTime + " 00:00:00",
        "endTime": this.endTime + " 59:59:59"
      };

      ApiService.query("/api/report/order", {
        params: params
      }).then(({
        data
      }) => {
        this.reports = data;
      })
    },
    changeRecent(days) {
      this.startTime = Moment(new Date(Date.now() - (days * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD");
      this.endTime = Moment(new Date()).format("YYYY-MM-DD");
      this.rencent = days;
      this.getReport();
    },

  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["currentUser"]),
  },
};
</script>